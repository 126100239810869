import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { useLocation } from '@reach/router';
import { Paper } from './common/shape';
import { Heading2, Heading1, Heading3 } from './common/typography';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  breakpoints as bp,
  Input,
  Label,
  TextArea,
  InputContainer,
  TextAreaContainer,
  LIGHT_GRAY_COLOR,
  GRAY_COLOR_RGBA,
  DANGER_COLOR,
  Button,
  ButtonLink,
} from '@operoute/ui';

const Section = styled.section`
  display: grid;
  row-gap: 1rem;
`;

const ContactPaper = styled(Paper)`
  display: grid;
  grid-template-rows: ${(props) => (props.isThankyou ? '1fr' : '1fr 1fr')};
  row-gap: 2rem;
  padding: 1rem;
  min-height: 300px;
  box-sizing: border-box;

  ${bp[1]} {
    grid-template-columns: ${(props) => (props.isThankyou ? '1fr' : '1fr 1fr')};
    grid-template-rows: unset;
    column-gap: 1.5rem;
    row-gap: unset;
    min-height: 400px;
    padding: 1.5rem;
  }
`;

const FormContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-content: center;
  row-gap: 1rem;
  padding: 1rem;
  border: 1px solid ${GRAY_COLOR_RGBA(0.8)};
  background-color: ${LIGHT_GRAY_COLOR};

  ${bp[0]} {
    grid-template-columns: minmax(300px, 420px);
    row-gap: 1.5rem;
  }
`;

const FormButton = styled(Button)`
  padding-left: 4rem;
  padding-right: 4rem;
`;

const FormLabelInput = styled(Label)`
  max-width: 60px;
`;

const InnerContent = styled.div`
  display: grid;
  grid-template-rows: 1fr 42px;
  row-gap: 1rem;
`;

const ContactBlock = styled.div`
  display: grid;
  align-items: center;
  align-content: center;
  justify-content: center;
  row-gap: 1.5rem;
  border: 1px solid ${GRAY_COLOR_RGBA(0.8)};
  text-align: center;
  grid-row: 1 / 3;

  ${bp[1]} {
    grid-row: unset;
  }
`;

const ContactLink = styled(ButtonLink)`
  font-size: 1.5rem;
  font-weight: 300;
`;

const ThankyouContainer = styled.div`
  display: grid;
  row-gap: 1rem;
  align-content: center;
  justify-content: center;
  text-align: center;
`;

const RecaptchaContainer = styled.div`
  display: grid;
  justify-content: center;
  padding-left: 2rem;
`;

const ErrorContainer = styled.div`
  display: grid;
  justify-content: flex-start;
  padding-left: 4.6rem;
  font-size: 0.8rem;
  margin: -1em 0 -1em;
  color: ${DANGER_COLOR};
`;

const ErrorCaptchaContainer = styled.div`
  display: grid;
  justify-content: flex-start;
  padding-top: 0.6rem;
  font-size: 0.8rem;
  color: ${DANGER_COLOR};
`;

const Contact = () => {
  const [isThankyou, setIsThankyou] = useState(false);
  const [recaptcha, setRecaptcha] = useState(null);
  const [name, setName] = useState({field: '', valid: null});
  const [email, setEmail] = useState({field: '', valid: null});
  const [inquiry, setInquiry] = useState({field: '', valid: null});
  const { search } = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(search);
    const message = params.get('message');
    if (message === 'thank-you') {
      setIsThankyou(true);
    } else {
      setIsThankyou(false);
    }
  }, [search]);

  const regex = {
    name: /^[a-zA-ZÀ-ÿ\s]{1,40}$/,
    email: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
  }

  const nameValidation = () => {
    if(regex.name){
      if (regex.name.test(name.field)){
        setName({...name, valid: 'true'});
      } else {
        setName({...name, valid: 'false'});
      }
    }
  }

  const emailValidation = () => {
    if(regex.email){
      if (regex.email.test(email.field)){
        setEmail({...email, valid: 'true'});
      } else {
        setEmail({...email, valid: 'false'});
      }
    }
  }

  const inquiryValidation = () => {
    if(!inquiry.field.trim()){
      setInquiry({...inquiry, valid: 'false'});
    }else{
      setInquiry({...inquiry, valid: 'true'});
    }
  }

  const captcha = useRef(null);

  const onChange = () => {
    if(captcha.current.getValue()){
      setRecaptcha(true);
    }
  }

  const onSubmit = (e) => {

    if(captcha.current.getValue()){
      setRecaptcha(true);
    }else{
      setRecaptcha(false);
    }

    if(!name.field.trim()){
      setName({...name, valid: 'false'});
    }

    if(!email.field.trim()){
      setEmail({...email, valid: 'false'});
    }

    if(!inquiry.field.trim()){
      setInquiry({...inquiry, valid: 'false'});
    }

    if(name.valid === 'true' && email.valid === 'true' && inquiry.valid === 'true' && recaptcha === true){
      setName({field: '', valid: null});
      setEmail({field: '', valid: null});
      setInquiry({field: '', valid: null});
      captcha.current.reset();
    }else{
      e.preventDefault();
    }
  }

  return (
    <Section id="contact">
      <Heading2>Have some questions?</Heading2>
      <Heading1>Send Us an Email</Heading1>
      <ContactPaper isThankyou={isThankyou}>
        {isThankyou ? (
          <InnerContent>
            <ThankyouContainer>
              <Heading2 as="h4">Thanks for contacting us.</Heading2>
              <Heading3 as="p">
                Your message has been successfully sent. We will contact you
                very soon!
              </Heading3>
            </ThankyouContainer>
          </InnerContent>
        ) : (
          <>
            <InnerContent
              as="form"
              action="/?message=thank-you#contact"
              name="contact"
              method="POST"
              data-netlify-recaptcha="true"
              netlify-honeypot="bot-field"
              data-netlify="true"
              onSubmit={onSubmit}
            >
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contact" />
              <FormContainer>
                <InputContainer>
                  <FormLabelInput htmlFor="full_name">Full name</FormLabelInput>
                  <Input name="full_name" type="text" value={name.field} onChange={ (e) => setName({...name, field: e.target.value})} onKeyUp={nameValidation} onBlur={nameValidation} valid={name.valid} />
                </InputContainer>
                {
                  ( name.valid === 'false' ) && (
                    <ErrorContainer>Please enter a valid full name</ErrorContainer>
                  )
                }
                <InputContainer>
                  <FormLabelInput htmlFor="email">Email</FormLabelInput>
                  <Input name="email" type="email" value={email.field} onChange={(e) => setEmail({...email, field: e.target.value})} onKeyUp={emailValidation} onBlur={emailValidation} valid={email.valid} />
                </InputContainer>
                {
                  ( email.valid === 'false'  ) && (
                    <ErrorContainer>Please enter a valid email</ErrorContainer>
                  )
                }
                <TextAreaContainer>
                  <FormLabelInput htmlFor="inquiry">Inquiry</FormLabelInput>
                  <TextArea name="inquiry" type="text" value={inquiry.field} onChange={(e) => setInquiry({...inquiry, field: e.target.value})} onKeyUp={inquiryValidation} onBlur={inquiryValidation} valid={inquiry.valid} />
                </TextAreaContainer>
                {
                  ( inquiry.valid === 'false'  ) && (
                    <ErrorContainer>Please enter a valid inquiry</ErrorContainer>
                  )
                }
                <RecaptchaContainer>
                  <ReCAPTCHA
                    sitekey={ process.env.RECAPTCHA_SITE_KEY }
                    onChange={onChange}
                    ref={captcha}
                    hl="en"
                  />
                  {
                    recaptcha === false && (
                      <ErrorCaptchaContainer>reCaptcha is required</ErrorCaptchaContainer>
                    )
                  }
                </RecaptchaContainer>
              </FormContainer>
              <div>
                <FormButton color="white" bgcolor="primary" type="submit">
                  Send message
                </FormButton>
              </div>
            </InnerContent>
            <InnerContent>
              <ContactBlock>
                <strong>Or call us</strong>
                <span>
                  <ContactLink isExternal isNoStyle to="tel:+15204818665">
                    520 481 8665
                  </ContactLink>
                </span>
              </ContactBlock>
            </InnerContent>
          </>
        )}
      </ContactPaper>
    </Section>
  );
};

export default Contact;
